import React, { useEffect, useState, useContext, useRef } from 'react';
import { useForm, Controller } from "react-hook-form"
import apiAdmin from '../../../services/api-admin';
import { Editor } from 'react-draft-wysiwyg';
import './../../styles/react-draft-wysiwyg.css';
import OutlinedDiv from '../../layout/OutlinedDiv';
import { FormatHTMLForWYSIWYGEditor, MultipleEmailAddressRegex } from '../../../services/utilities';
import draftToHtml from 'draftjs-to-html';
import { convertToRaw, EditorState } from 'draft-js';
import { MdPersonAdd, MdSave, MdSaveAs } from 'react-icons/md';
import { BsSendFill } from "react-icons/bs";
import { FaFilePdf } from "react-icons/fa";
import { TextField, MenuItem } from '@mui/material';
import { bootstrap } from '../../../app';
import fileDownload from 'js-file-download';
import some from 'lodash/some';
import { AdminUserContext } from '../../../contexts/user-context';
import EcardEditorTeamMember from './ecard-editor-team-member';
import { DateTime } from 'luxon';

const EcardEditor = ({ cardDesign, cardTemplateToUse, setSaveTemplateResponse, startOver }) => {
    const { adminUserProfile } = useContext(AdminUserContext);
    const { handleSubmit, control, trigger, watch, setValue, getValues, clearErrors, register, formState: { errors } } = useForm();
    const [teamMembers, setTeamMembers] = useState();
    const [userSender, setUserSender] = useState();
    const [senders, setSenders] = useState([]);
    const [templateSaved, setTemplateSaved] = useState(false);
    const [dummySenderId, setDummySenderId] = useState(-1);
    const [errorSavingTemplate, setErrorSavingTemplate] = useState();
    const [errorGenerating, setErrorGenerating] = useState();
    const [errorSendingOrDownloading, setErrorSendingOrDownloading] = useState();
    const [sending, setSending] = useState(false);
    const [downloading, setDownloading] = useState(false);
    const [ecardSent, setEcardSent] = useState(false);
    const [ecardDownloaded, setEcardDownloaded] = useState(false);
    const ecardPreviewModal = useRef();
    const [ecardPreviewHtml, setEcardPreviewHtml] = useState();
    const watchSubmissionType = watch("submissionType");
    const [emailBodyEditorState, setEmailBodyEditorState] = useState(
        () => EditorState.createEmpty(),
    )
    const showHoliday = (DateTime.now().month === 11) || (DateTime.now().month === 12);

    useEffect(() => {
        if (!cardTemplateToUse) return;
        setValue("subject", cardTemplateToUse.subject);
        setValue("salutation", cardTemplateToUse.salutation);
        setValue("body", cardTemplateToUse.body);
        setValue("designHeading", cardTemplateToUse.designHeading);
        setEmailBodyEditorState(FormatHTMLForWYSIWYGEditor(cardTemplateToUse.body));
    }, [cardTemplateToUse]);

    const saveTemplate = (saveAsNew) => {

        var templateData = {
            brandId: 1, //get from user or selection
            subject: getValues('subject'),
            salutation: getValues("salutation"),
            designHeading: getValues('designHeading'),
            body: draftToHtml(convertToRaw(emailBodyEditorState.getCurrentContent())),
            design: cardDesign.title,
            templateSenders: senders.map(s => {
                return {
                    senderCopied: s.senderCopied,
                    senderPhotoIncluded: s.senderPhotoIncluded,
                    teamMemberId: s.teamMember.teamMemberId
                };
            })
        };

        if (saveAsNew) {
            templateData.templateId = 0;
            templateData.templateName = getValues('newTemplateName');
        }
        else if (cardTemplateToUse) {
            templateData.templateId = cardTemplateToUse.templateId;
            templateData.templateName = cardTemplateToUse.templateName;
            templateData.createdOn = cardTemplateToUse.createdOn;
        }


        const saveEcardTemplate = async (templateData) => {
            apiAdmin.post(`/ecard/save-template`, { ...templateData }, { noErrorHandling: true })
                .then((response) => {
                    setTemplateSaved(true);
                    setSaveTemplateResponse(response.data);
                    setErrorSavingTemplate();
                })
                .catch((err) => {
                    console.log(err.message);
                    setErrorSavingTemplate("Error saving template. " + err.message);
                });
        };

        saveEcardTemplate(templateData);
        setValue('newTemplateName', '');
    };

    useEffect(() => {
        if (!cardDesign || !adminUserProfile) return;

        const getTeamMembers = async () => {
            apiAdmin.get(`/ecard-senders`)
                .then((response) => {
                    setTeamMembers(response.data);
                    var userMember = response.data.find(t => t.email.toLowerCase() === adminUserProfile.email.toLowerCase());
                    var userSenderObj = { senderId: 0, senderPhotoIncluded: false, senderCopied: false, teamMember: userMember };
                    setUserSender(userSenderObj);
                });
        }
        getTeamMembers();
    }, [cardDesign, adminUserProfile]);

    useEffect(() => {
        if (!userSender || !teamMembers) return;
        if (cardTemplateToUse) {
            var activeSenders = cardTemplateToUse.templateSenders.filter(ts => some(teamMembers, t => t.teamMemberId === ts.teamMemberId));
            setSenders(activeSenders);
            return;
        }
        setSenders([userSender]);
    }, [userSender, cardTemplateToUse, teamMembers]);


    const getUniqueDummySenderId = () => {
        var currentDummySenderId = dummySenderId;
        setDummySenderId(currentDummySenderId - 1);
        return currentDummySenderId;
    };

    const updateSender = (updatedSender) => {
        var updatedSenders = senders.map((s) => {
            if (s.senderId === updatedSender.senderId) {
                return updatedSender;
            }
            else {
                return s;
            }
        });
        setSenders(updatedSenders);
    };
    const removeSender = (senderToRemove) => {
        setSenders(senders.filter(s => s.senderId !== senderToRemove.senderId));
    };

    const buildCardDataObject = () => {
        return {
            ...getValues(),
            body: draftToHtml(convertToRaw(emailBodyEditorState.getCurrentContent())),
            design: cardDesign.title,
            brand: cardDesign.brand,
            brandId: cardDesign.brandId,
            templateSenders: senders
        };
    };
    const previewEcard = () => {

        const getPreviewHtml = async (cardData) => {
            apiAdmin.post(`/ecard/generate-html`, { ...cardData }, { noErrorHandling: true })
                .then((response) => {
                    var previewModal = new bootstrap.Modal(ecardPreviewModal.current);

                    ecardPreviewModal.current.addEventListener('hidden.bs.modal', event => {
                        setEcardDownloaded(false);
                        setEcardSent(false);
                        setErrorSendingOrDownloading();
                    });

                    previewModal.show();
                    setEcardPreviewHtml(response.data);
                    setErrorGenerating();
                })
                .catch((err) => {
                    setErrorGenerating("Error generating preview. " + err.message);
                });
        };

        trigger("body").then((valid) => {
            if (valid) {
                getPreviewHtml(buildCardDataObject());
            }
        });
    };

    const sendEcard = () => {
        const doSend = async (cardData) => {
            apiAdmin.post(`/ecard/send`, { ...cardData }, { noErrorHandling: true })
                .then((response) => {
                    setEcardSent(true);
                })
                .catch((err) => {
                    setErrorSendingOrDownloading("Error sending. " + err.message);
                })
                .finally(() => {
                    setSending(false);
                });
        };

        trigger().then((valid) => {
            if (valid) {
                setSending(true);
                doSend(buildCardDataObject());
            }
        });
    };

    const downloadEcard = () => {
        const doDownload = async (cardData) => {
            apiAdmin.post(`/ecard/download`, { ...cardData }, { responseType: 'blob' })
                .then((response) => {
                    fileDownload(response.data, `ecard.pdf`);
                    setEcardDownloaded(true);
                })
                .catch((err) => {
                    setErrorSendingOrDownloading("Error downloading. " + err.message);
                })
                .finally(() => {
                    setDownloading(false);
                });;
        };

        clearErrors();
        trigger("body").then((valid) => {
            if (valid) {
                setDownloading(true);
                doDownload(buildCardDataObject());
            }
        });
    };

    return (
        <div className="ecard-editor">
            <h3>Create a new {cardDesign.title} Ecard {cardTemplateToUse && <>Using your "{cardTemplateToUse.templateName}" template</>}</h3>
            <div className="mb-3"><button className="btn btn-primary" onClick={startOver}>Start Over</button></div>
            <form onSubmit={handleSubmit(sendEcard)}>
                <div className="mb-3">
                    <TextField
                        label="Email Address(es) *" autoComplete="off"
                        {...register("emailAddresses", {
                            required: true,
                            pattern: MultipleEmailAddressRegex
                        })}
                        className="w-100"
                    />
                    <small className="text-primary fst-italic">Enter up to 10 email addresses, separated with a semi-colon(;).</small>
                    {errors?.emailAddresses?.type === "required" && <small className="text-danger ps-2">This field is required for sending an ecard.</small>}
                    {errors?.emailAddresses?.type === "pattern" && <small className="text-danger ps-2">Please check the email address format.  Separate multiple emails with a semi-colon(;).</small>}
                </div>
                <div className="mb-3">
                    <TextField
                        label={`Email Subject Line${watchSubmissionType === "email" ? " *" : ""}`}
                        {...register("subject", { required: true })}
                        className="w-100"
                    />
                    {errors?.subject?.type === "required" && <small className="text-danger">This field is required for sending an ecard.</small>}
                </div>
                <div className={`mb-3 ${(cardDesign.title === "Meet Your Team") ? "d-none" : ""}`}>
                    <Controller
                        name="designHeading"
                        id="designHeading"
                        control={control}
                        defaultValue={(cardDesign.title === "Meet Your Team") ? "Meet the Team" : "None"}
                        render={({ field }) => (
                            <TextField
                                select
                                label="Heading Design"
                                className="w-100"
                                {...field}>
                                <MenuItem value="None">None</MenuItem>
                                {cardDesign.title === "Modern" && [
                                    <MenuItem key="in-the-loop" value="In the Loop">In the Loop</MenuItem>,
                                    <MenuItem key="bloom-with-us" value="Bloom with us">Bloom with us</MenuItem>
                                ]}
                                {showHoliday &&
                                    <MenuItem value="Happy Holidays">Happy Holidays</MenuItem>
                                }
                                <MenuItem value="Congratulations">Congratulations</MenuItem>
                                <MenuItem value="Happy Birthday">Happy Birthday</MenuItem>
                                <MenuItem value="Thank You">Thank You</MenuItem>
                                <MenuItem value="You're Invited">You're Invited</MenuItem>
                                <MenuItem value="Exciting News">Exciting News</MenuItem>
                                <MenuItem value="Meet the Team">Meet the Team</MenuItem>
                                <MenuItem value="Welcome">Welcome</MenuItem>
                            </TextField>
                        )} />
                </div>
                <div className={`mb-3 ${(cardDesign.title === "Meet Your Team") ? "d-none" : ""}`}>
                    <TextField
                        label="Salutation"
                        {...register("salutation")}
                        className="w-100"
                    />
                </div>
                <div className="mb-3">
                    <OutlinedDiv label="Message">
                        <Controller
                            name="body"
                            control={control}
                            rules={{
                                validate: {
                                    bodyEditorNotEmpty: v => {
                                        if ((typeof v) === "string" && v !== "") return true;
                                        if (!v.blocks) {
                                            return false;
                                        }
                                        return v.blocks.length > 0 && v.blocks.map(b => { return b.text }).join('').replaceAll(/\s/g) !== "";
                                    }
                                }
                            }}
                            defaultValue=""
                            render={({ field }) => (
                                <Editor
                                    toolbar={{ options: ['inline', 'fontSize', 'list', 'textAlign', 'colorPicker', 'link', 'emoji', 'remove'] }}
                                    editorState={emailBodyEditorState}
                                    editorClassName="wysiwyg"
                                    onEditorStateChange={(e) => setEmailBodyEditorState(e)}
                                    {...field}
                                />
                            )}
                        />
                    </OutlinedDiv>
                    {errors?.body?.type === "bodyEditorNotEmpty" && <small className="text-danger">This field is required</small>}
                </div>
                <div className="mb-3">
                    <OutlinedDiv label="Senders">
                        <small>Max {cardDesign.maxSenders} for this design</small>
                        {senders.map((s, idx) => {
                            return <EcardEditorTeamMember key={idx} teamMembers={teamMembers} sender={s} updateSender={updateSender} removeSender={removeSender} senderCount={senders.length} />
                        })}
                        {(senders.length < cardDesign.maxSenders) && <button type="button" className="btn btn-link" onClick={() => { setSenders([...senders, { ...userSender, senderId: getUniqueDummySenderId() }]); }}><MdPersonAdd title="Add Sender" className="md-react-icons pe-1" />Add another sender</button>}
                    </OutlinedDiv>
                </div>
                <div className="mb-3">
                    <OutlinedDiv label="Template">
                        <div className="row">
                            <div className="col-12 col-md-8">
                                {cardTemplateToUse && <>
                                    <div className="d-block mb-2">
                                        <span className="fw-bold">Template Name: </span><span className="mr-2"> {cardTemplateToUse.templateName} </span>
                                        <button type="button" className="btn btn-primary" onClick={() => { saveTemplate(); }}><MdSaveAs title="Update saved template" className="lg-react-icons pe-2" />Update Saved Template</button>
                                    </div>
                                    <div>Or</div>
                                </>
                                }
                                <TextField id="newTemplateName" label="New template name" className="me-2" variant="standard" {...register("newTemplateName")} />
                                <button type="button" className="btn btn-primary me-2" onClick={() => { if (getValues('newTemplateName') === "") { setErrorSavingTemplate("Please enter a name for the template."); return; } saveTemplate({ saveAsNew: true }) }}><MdSave title="Update saved template" className="lg-react-icons pe-2" />Save As New Template</button>
                            </div>
                            <div className="col-12 col-md-4">
                                {templateSaved && <div className="alert alert-success alert-dismissible mt-2" role="alert">Template saved successfully!<button type="button" className="btn-close" onClick={() => setTemplateSaved(false)} aria-label="Close"></button></div>}
                            </div>
                            {errorSavingTemplate && <div className="col-12">
                                <small className="text-danger">{errorSavingTemplate}</small>
                            </div>}
                        </div>
                    </OutlinedDiv>
                </div>
                <div className="mb-3">
                    <OutlinedDiv label="Finish Up">
                        <div className="row p-3">
                            <span className="btn btn-primary w-auto me-3" onClick={previewEcard}><BsSendFill title="Generate Preview" className="lg-react-icons pe-2" />Generate Preview</span>
                        </div>
                        {errorGenerating && <div className="alert alert-danger alert-dismissible mt-2" role="alert">{errorGenerating}<button type="button" className="btn-close" onClick={() => errorGenerating()} aria-label="Close"></button></div>}
                    </OutlinedDiv>
                </div>
            </form>

            <div className="modal" id="ecardPreviewModal" ref={ecardPreviewModal} tabIndex="-1" data-bs-keyboard="false" aria-labelledby="ecardPreviewModalLabel" aria-hidden="true" role="dialog">
                <div className={`modal-dialog modal-fullscreen-md-down ${(cardDesign.title === "Meet Your Team") ? "modal-xl" : "modal-lg"}`}>
                    <div className="modal-content">
                        <div className="modal-header">
                            <h3 className="modal-title" id="ecardPreviewModalLabel">Ecard Preview</h3>
                            <button type="button" disabled={sending || downloading} className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="p-2">
                            <div className="text-danger text-small text-center pb-1">Please review your ecard carefully and spellcheck your content.  Anything you send is a reflection of our entire team.</div>
                            <div className="text-center pb-1">
                                <button type="button" disabled={sending || downloading || ecardSent} className="btn btn-primary w-auto me-3" onClick={() => handleSubmit(sendEcard)()}>{sending ? <><span className="spinner-border spinner-border-sm" aria-hidden="true"></span><span role="status">Sending...</span></> : <><BsSendFill title="Send Ecard" className="lg-react-icons pe-2" />Send</>}</button>
                                <button type="button" disabled={sending || downloading || ecardDownloaded} className="btn btn-primary w-auto" onClick={downloadEcard}>{downloading ? <><span className="spinner-border spinner-border-sm" aria-hidden="true"></span><span role="status">Downloading...</span></> : <><FaFilePdf title="Download Pdf" className="lg-react-icons pe-2" />Download Pdf</>}</button>
                            </div>
                            <div className="text-center pb-1">
                                {ecardSent && <div className="alert alert-success alert-dismissible mt-2" role="alert">Ecard Sent!<button type="button" className="btn-close" onClick={() => setEcardSent(false)} aria-label="Close"></button></div>}
                                {ecardDownloaded && <div className="alert alert-success alert-dismissible mt-2" role="alert">Ecard Downloaded!<button type="button" className="btn-close" onClick={() => setEcardDownloaded(false)} aria-label="Close"></button></div>}
                                {errorSendingOrDownloading && <div className="alert alert-danger alert-dismissible mt-2" role="alert">{errorSendingOrDownloading}<button type="button" className="btn-close" onClick={() => { setErrorSendingOrDownloading(false); setEcardDownloaded(false); setEcardSent(false); }} aria-label="Close"></button></div>}
                            </div>
                            {Object.keys(errors).length > 0 && <div className="text-danger text-center pb-1">There are some missing/incorrect entries on your ecard.  Please review and try again.</div>}
                            <div className="ecard-preview-src" dangerouslySetInnerHTML={{ __html: ecardPreviewHtml }}></div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" disabled={sending || downloading} className="btn btn-primary m-auto" data-bs-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default EcardEditor;
