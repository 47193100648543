/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useRef } from 'react';
import { Helmet } from 'react-helmet-async';
import { DataGridPro, GridToolbarContainer, GridActionsCellItem, GridToolbarQuickFilter } from '@mui/x-data-grid-pro';
import apiAdmin from '../../services/api-admin';
import { MdEdit, MdDelete, MdAdd, MdQrCode2, MdOutlineEmail, MdDownload } from 'react-icons/md';
import { BsSendFill } from "react-icons/bs";
import { useForm, Controller} from "react-hook-form";
import { TextField, MenuItem } from '@mui/material';
import { DateTime } from 'luxon';
import { QRCode } from 'react-qrcode-logo';
import { MultipleEmailAddressRegex } from '../../services/utilities';
import some from 'lodash/some';
import './digital-home-portfolios.scss';

const DigitalHomePortfolios = (props) => {
    const { register, handleSubmit, setValue, reset, control, formState: { errors, isDirty, isSubmitted } } = useForm();
    const { register: registerEmailField, handleSubmit: handleEmailSubmit, formState: { errors: emailErrors } } = useForm();
    const [digitalHomePortfolios, setDigitalHomePortfolios] = useState([]);
    const [dhpFiles, setDhpFiles] = useState([]);
    const [loading, setLoading] = useState(true);
    const [modalQRValue, setModalQRValue] = useState({});
    const [digitalHomePortfolioToEdit, setDigitalHomePortfolioToEdit] = useState(null);
    const [digitalHomePortfolioToDelete, setDigitalHomePortfolioToDelete] = useState(null);
    const [digitalHomePortfolioToEmail, setDigitalHomePortfolioToEmail] = useState(null);
    const [sending, setSending] = useState(false);
    const [dhpSent, setDhpSent] = useState(false);
    const [deleted, setDeleted] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [error, setError] = useState(null);
    const [errorSending, setErrorSending] = useState(null);
    const [brands, setBrands] = useState([]);
    const [selectedBrand, setSelectedBrand] = useState(null);
    const qrRef = useRef();
    const qrModalRef = useRef();
    const qrEmailRef = useRef();

    const columns = [
        { field: 'propertyAddress', headerName: 'Address', flex: 1 },
        { field: 'filePath', headerName: 'Home Portfolio File Name', flex: 1, valueGetter: (val) => { return (val) ? val.replace(/^.*[\/]/, '') : ""; } },
        { field: 'createdByAdminUserEmail', headerName: 'Created By', flex: .5 },
        { field: 'createdOn', headerName: 'Created', flex: .5, type: 'date', valueGetter: (val) => { return DateTime.fromISO(val).toJSDate(); }, valueFormatter: (val) => { return DateTime.fromJSDate(val).toLocaleString(DateTime.DATETIME_SHORT); } },
        { field: 'expiresOn', headerName: 'Expires', flex: .4, type: 'date', valueGetter: (val) => { return DateTime.fromISO(val).toJSDate(); } },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            flex: .5,
            cellClassName: 'actions',
            getActions: (params) => {

                return [
                    <GridActionsCellItem
                        icon={<MdEdit />}
                        label="Edit"
                        onClick={() => { setDigitalHomePortfolioToEdit(params.row); }}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        icon={<MdDelete />}
                        label="Delete"
                        onClick={() => { setDigitalHomePortfolioToDelete(params.row); setDeleted(false) }}
                        color="inherit"
                        data-bs-toggle="modal"
                        data-bs-target="#deleteModal" role="button"
                    />,
                    <GridActionsCellItem
                        icon={<MdQrCode2 />}
                        disabled={!params.row.filePath}
                        label="Generate QR"
                        onClick={() => {
                            setModalQRValue({
                                qrPath: `${selectedBrand.url.split(';')[0]}/digital-home-portfolio/${params.row.digitalHomePortfolioId}`,
                                fileName: `QRCode-${params.row.propertyAddress.replace(/[^\w\s]/g, '')}`,
                                propertyAddress: params.row.propertyAddress
                            });
                        }}
                        data-bs-toggle="modal"
                        data-bs-target="#qrModal" role="button"
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        icon={<MdOutlineEmail />}
                        disabled={!params.row.filePath}
                        label="Email"
                        onClick={() => {
                            setModalQRValue({
                                qrPath: `${selectedBrand.url.split(';')[0]}/digital-home-portfolio/${params.row.digitalHomePortfolioId}`,
                                fileName: `QRCode-${params.row.propertyAddress.replace(/[^\w\s]/g, '')}`,
                                propertyAddress: params.row.propertyAddress
                            });
                            setDigitalHomePortfolioToEmail(params.row);
                            setDhpSent(false);
                        }}
                        color="inherit"
                        data-bs-toggle="modal"
                        data-bs-target="#emailModal" role="button"
                    />,
                ];
            }
        }
    ];

    const getRowId = (row) => {
        return row.digitalHomePortfolioId;
    }


    useEffect(() => {
        const getBrands = async () => {
            apiAdmin.get(`/brands`)
                .then((response) => {
                    setBrands(response.data.filter(b => b.brandId !== 4));
                    setSelectedBrand(response.data[0]);
                });
        }
        getBrands();
    }, []);

    const downloadQrFromEdit = () => {
        if (!qrRef.current) return;
        qrRef.current.download("png", `QRCode-${digitalHomePortfolioToEdit.propertyAddress.replace(/[^\w\s]/g, '')}`);
    }

    const downloadQrFromModal = () => {
        qrModalRef.current.download("png", modalQRValue.fileName);
    };

    useEffect(() => {
        if (!selectedBrand) return;
        const getDHPFiles = async (alreadyUsedFiles) => {
            apiAdmin.get(`/digitalhomeportfolios/files?selectedBrandName=${selectedBrand.brandName}`)
                .then((response) => {
                    setDhpFiles(response.data);
                    setLoading(false);
                });
        }

        const getDigitalHomePortfolios = async () => {
            apiAdmin.get(`/digitalhomeportfolios?selectedBrandId=${selectedBrand.brandId}`)
                .then((response) => {
                    setDigitalHomePortfolios(response.data);
                    getDHPFiles(response.data.map(d => { return d.filePath }));
                });
        }

        setLoading(true);
        getDigitalHomePortfolios();
    }, [selectedBrand]);



    useEffect(() => {
        if (!digitalHomePortfolioToEdit || digitalHomePortfolioToEdit.digitalHomePortfolioId === 0 || !dhpFiles) return;

        setValue("propertyAddress", digitalHomePortfolioToEdit.propertyAddress);
        if (dhpFiles.includes(digitalHomePortfolioToEdit.filePath)) setValue("filePath", digitalHomePortfolioToEdit.filePath);
        
    }, [digitalHomePortfolioToEdit, dhpFiles]);

    const onSaveSubmit = data => {
        const saveDigitalHomePortfolio = async (obj) => {
            await apiAdmin.post(`/digitalhomeportfolio/save`, obj)
                .then((response) => {
                    if (response.data) {
                        setDigitalHomePortfolioToEdit(response.data);
                        setSubmitted(true);

                        var updatedDHPs = digitalHomePortfolios.map(d => {
                            if (d.digitalHomePortfolioId === response.data.digitalHomePortfolioId) {
                                return response.data;
                            }
                            else {
                                return d;
                            }
                        });
                        if (!some(digitalHomePortfolios, d => d.digitalHomePortfolioId === response.data.digitalHomePortfolioId)) {
                            updatedDHPs.push(response.data);
                        }
                    } else { 
                        setError("There was an error saving the home portfolio. Please try again.")
                    }
                    setDigitalHomePortfolios(updatedDHPs);
                });
        }
        const digitalHomePortfolioObject = {
            ...data,
            digitalHomePortfolioId: (digitalHomePortfolioToEdit.digitalHomePortfolioId === 0) ? "00000000-0000-0000-0000-000000000000" : digitalHomePortfolioToEdit.digitalHomePortfolioId,
            brandId: selectedBrand.brandId
        }

        if (digitalHomePortfolioObject.filePath === "None") digitalHomePortfolioObject.filePath = null;
        saveDigitalHomePortfolio(digitalHomePortfolioObject);
    }

    const onEmailSubmit = data => {
        const sendDigitalHomePortfolio = async (obj) => {
            await apiAdmin.post(`/digitalhomeportfolio/email`, obj, {
                headers: {
                    'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
                }
            })
                .then(() => {
                    setSending(false);
                    setDhpSent(true);
                })
                .catch((err) => {
                    setErrorSending("Error sending. " + err.message);
                })
                .finally(() => {
                    setSending(false);
                });
        };
        setSending(true);

        qrEmailRef.current.canvasRef.current.toBlob((b) => {
            var dhpSendObj = {
                ...data,
                ...digitalHomePortfolioToEmail,
                emailFrom: selectedBrand?.regions[0].stateAndCounties[0].csEmailAddress,
                qrBytes: b
            }
            sendDigitalHomePortfolio(dhpSendObj);
        });

    };

    const deleteDigitalHomePortfolio = () => {
        const deleteMessage = async () => {
            await apiAdmin.post(`/digitalhomeportfolio/delete`, digitalHomePortfolioToDelete)
                .then((response) => {
                    if (response.statusText === "OK") {
                        setDigitalHomePortfolioToDelete(null);
                        setDeleted(true);
                        setDigitalHomePortfolios(digitalHomePortfolios.filter(d => d.digitalHomePortfolioId !== digitalHomePortfolioToDelete.digitalHomePortfolioId));
                    }
                    else { 
                        setError("There was an error deleting the home portfolio. Please try again.")
                    }
                });
        }
        deleteMessage();
    }

    const unUsedOrBeingEdited = (f) => {
        return !(digitalHomePortfolios.map(dhp => { return dhp.filePath; }).includes(f)) || (digitalHomePortfolioToEdit?.filePath === f);
    }

    function EditToolbar(props) {
        return (
            <GridToolbarContainer>
                <span className="btn-link btn" onClick={() => { setDigitalHomePortfolioToEdit({ digitalHomePortfolioId: 0, brandId: selectedBrand.brandId, }) }}>
                    <MdAdd className="mb-1" /> Create New Digital Home Portfolio QR
                </span>
                {selectedBrand &&
                    <div className="col me-3">
                        <div className="dropdown float-end">
                            <button className="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                {selectedBrand.brandName}
                            </button>
                            <ul className="dropdown-menu">
                                {brands && brands.map((brand, index) => {
                                    return <li key={"brand_" + brand.brandId}><span className="dropdown-item btn-link" onClick={() => setSelectedBrand(brand)}>{brand.brandName}</span></li>
                                })}
                            </ul>
                        </div>

                    </div>
                }
                <GridToolbarQuickFilter />
            </GridToolbarContainer>
        );
    }
    
    return (
        <div>
            <Helmet>
                <title>Digital Home Portfolios</title>
            </Helmet>
            <h1>Digital Home Portfolios</h1>
            
            {digitalHomePortfolioToEdit || (digitalHomePortfolioToEdit && digitalHomePortfolioToEdit.digitalHomePortfolioId === 0) ? <>

                <form onSubmit={handleSubmit(onSaveSubmit)}>
                    <div className="border p-3">
                        <div className="mb-3">
                            <TextField
                                label="Property Address *"
                                {...register("propertyAddress", {required: true})}
                                className="w-100"
                            />
                            {errors?.propertyAddress?.type === "required" && <small className="text-danger">This field is required</small>}
                        </div>
                        <div className="mb-3">
                            <Controller
                                name="filePath"
                                id="filePath"
                                control={control}
                                defaultValue="None"
                                render={({ field }) => (
                                    <TextField
                                        select
                                        label="Select File"
                                        className="w-100"
                                        {...field}>
                                        <MenuItem key={0} value="None">No File Yet</MenuItem>
                                        {dhpFiles && dhpFiles.filter(unUsedOrBeingEdited).map((dhp, i) => {
                                            return <MenuItem key={i + 1} value={dhp}>{dhp.replace(/^.*[\/]/, '')}</MenuItem>
                                        }) }
                                    </TextField>
                                )} />
                            <div className="small">Files should be placed in S:\Customer Service\zDigitalHomePortfolioFiles\{selectedBrand.brandName.replace(' ', '').toLowerCase()}.</div>
                            {dhpFiles && (dhpFiles.filter(unUsedOrBeingEdited).length === 0) && <div className="text-danger">All available files have been assigned a QR code already.</div>}
                        </div>
                        {digitalHomePortfolioToEdit && digitalHomePortfolioToEdit.digitalHomePortfolioId !== 0 && digitalHomePortfolioToEdit.filePath && (!isDirty || isSubmitted) &&
                            <div className="mb-3">
                                <QRCode ref={qrRef} value={`${selectedBrand.url.split(';')[0]}/digital-home-portfolio/${digitalHomePortfolioToEdit.digitalHomePortfolioId}`}
                                    logoImage='/t-logo-whitecirclebackground.png'
                                    ecLevel="Q"
                                    size="500"
                                    removeQRCodeBehindLogo="true"
                                    logoWidth="150"
                                    enableCORS="true"
                                    id="qr-edit-view"
                                />
                                <button type="button" className="btn btn-primary d-block w-auto" onClick={downloadQrFromEdit}><MdDownload title="Download Qr" className="lg-react-icons pe-2" />Download Qr</button>
                            </div>
                        }
                    </div>
                    {submitted && <><div className="alert alert-success alert-dismissible mt-2" role="alert">Digital Home Portfolio saved successfully!<button type="button" className="btn-close" onClick={() => { setDigitalHomePortfolioToEdit(null); setSubmitted(false); setError(null); reset(); }} aria-label="Close"></button></div></>}
                    <div className="pt-2">
                        <button type="submit" className="btn btn-primary me-4">Save</button>
                        <button type="button" className="btn btn-primary" onClick={() => { setDigitalHomePortfolioToEdit(null); setSubmitted(false); setError(null); reset(); } }>Cancel</button>
                    </div>
                </form>
            </>
            :
            <>
                {deleted &&  <div className="alert alert-warning alert-dismissible" role="alert">Digital Home Portfolio deleted successfully!<button type="button" className="btn-close" onClick={() => setDeleted(false)} aria-label="Close"></button></div>}
                {error && <div className="alert alert-danger" role="alert">{error}</div>}
                <DataGridPro
                    rows={digitalHomePortfolios}
                    columns={columns}
                    getRowId={getRowId}
                    loading={loading}
                    slotProps={{
                        loadingOverlay: {
                            variant: 'skeleton',
                            noRowsVariant: 'skeleton',
                        },
                    }}
                    autoHeight
                    rowHeight={38}
                    disableRowSelectionOnClick
                    alignItems="center"
                    slots={{
                        toolbar: EditToolbar,
                    }}
                    initialState={{
                        pagination: {
                          paginationModel: { pageSize: 25, page: 0 },
                        },
                        sorting: { sortModel: [{ field: 'createdOn', sort: 'desc' }] }
                      }}
                    pagination
                />
            </>
            }

            <div className="modal shown" tabIndex="-1" id="deleteModal" >
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Delete Digital Home Portfolio?</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <p>Are you sure you want to delete this digital home portfolio?</p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">No, Cancel</button>
                            <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={() => deleteDigitalHomePortfolio()}>Yes, Delete</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal shown modal" tabIndex="-1" id="qrModal" >
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Download Digital Home Portfolio QR</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="d-block text-center">
                                <QRCode ref={qrModalRef}
                                    value={modalQRValue.qrPath}
                                    ecLevel="Q"
                                    size="500"
                                    logoWidth="150"
                                    enableCORS="true"
                                    logoImage='/t-logo-whitecirclebackground.png'
                                    removeQRCodeBehindLogo="true"
                                    id="qr-modal-view"
                                />
                            </div>                
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                            <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={downloadQrFromModal}>Download Qr</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal shown modal" tabIndex="-1" id="emailModal" >
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Email QR Code for {modalQRValue.propertyAddress} </h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => { setModalQRValue({ }); setDhpSent(false); }} ></button>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={handleEmailSubmit(onEmailSubmit)}>
                                <div className="mb-3">
                                    From: {selectedBrand?.regions[0].stateAndCounties[0].csEmailAddress}
                                </div>
                                <div className="mb-3">
                                    <TextField
                                        label="Email Address(es) *" autoComplete="email"
                                        {...registerEmailField("emailAddresses", {
                                            required: true,
                                            pattern: MultipleEmailAddressRegex
                                        })}
                                        className="w-100"
                                    />
                                    <small className="text-primary fst-italic">Separate multiple addresses with a semi-colon(;).</small>
                                    {emailErrors?.emailAddresses?.type === "required" && <small className="text-danger d-block">This field is required.</small>}
                                    {emailErrors?.emailAddresses?.type === "pattern" && <small className="text-danger d-block">Please check the email address format.  Separate multiple emails with a semi-colon(;).</small>}
                                </div>
                                {dhpSent && <div className="alert alert-success alert-dismissible mt-2" role="alert">Sent successfully!<button type="button" className="btn-close" aria-label="Close" onClick={() => { setDhpSent(false);}}></button></div>}
                                {errorSending && <div className="alert alert-danger alert-dismissible mt-2" role="alert">{errorSending}<button type="button" className="btn-close" onClick={() => { setErrorSending(false); setSending(false); }} aria-label="Close"></button></div>}
                                <button type="submit" disabled={sending} className="btn btn-primary w-auto me-3">{sending ? <><span className="spinner-border spinner-border-sm" aria-hidden="true"></span><span role="status">Sending...</span></> : <><BsSendFill title="Send" className="lg-react-icons pe-2" />Send</>}</button>
                            </form>
                            <div className="d-block text-center">
                                <QRCode ref={qrEmailRef}
                                    value={modalQRValue.qrPath}
                                    ecLevel="Q"
                                    size="500"
                                    logoWidth="150"
                                    enableCORS="true"
                                    logoImage='/t-logo-whitecirclebackground.png'
                                    removeQRCodeBehindLogo="true"
                                    id="qr-email-view"
                                />
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={() => { setModalQRValue({}); setDhpSent(false); } }>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default DigitalHomePortfolios;
