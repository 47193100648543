import currency from 'currency.js';
import ReactGA from 'react-ga4';
import { components } from 'react-select';
import htmlToDraft from 'html-to-draftjs';
import { EditorState, ContentState } from 'draft-js';


export const FilePath = (path) => {
  return process.env.REACT_APP_FILES_BASE_URL + path;
}

export const FormatNameToFilePath = (name) => {
    return name.toLowerCase().replace(/ {2}/g, " ").replace(/ /g, "-").replace(/(-)+/g, "-").replace(/[']/g, "").replace(/[.]/g, "");
}

export const EventTrack = (category, action, label) => {
    ReactGA.event({
        category: category,
        action: action,
        label: label,
    })
}

export const FormatHTMLForWYSIWYGEditor = (htmlString) => {
    var blocksFromHtml = htmlToDraft(htmlString);
    var { contentBlocks, entityMap } = blocksFromHtml;
    var contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
    return EditorState.createWithContent(contentState);
}

export const FormatPhoneNumber = (phoneNumberString) => {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
    return null;
}

export const FormatMoney = (number) => {
    if (!number && number !== 0) return null;
    return currency(number , { separator: ',', symbol: '$',   negativePattern: `(!#)` }).format();
}

export const FormatMoneyNoDollarSign = (number) => {
    if (!number && number !== 0) return null;
    return currency(number , { separator: ',', symbol: '',   negativePattern: `(!#)` }).format();
}

export const FormatNumber = (number) => {
    if (!number && number !== 0) return null;
    return number.toString().replace(/[^\d^\.]/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const UnFormatMoney = (string) => {
    return currency(string).value;
}

export const toTitleCase = (str) => {
  return str.toLowerCase().replace(/\b\w/g, s => s.toUpperCase());
}

export const FormatPhoneInput = (value) => {
    if (!value) return value;
    const phoneNumber = value.replace(/[^\d]/g, "");
    const phoneNumberLength = phoneNumber.length;
    if (phoneNumberLength < 4) return phoneNumber;
    if (phoneNumberLength < 7) {
        return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3)}`;
    }
    return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
}

export const DisplayHtml = (stringWithHtml) => {
    var createMarkup = (html) => {
        return { __html: html };
    };
    return <div dangerouslySetInnerHTML={createMarkup(stringWithHtml)} />;
}

export const ReactSelectInputNoAutoComplete = ({ children, ...props }) => {
    return (
        <components.Input {...props} autoComplete="chrome-off">
            {children}
        </components.Input>
    );
};

export const SingleEmailAddressRegex = /^[0-9a-zA-Z]+([0-9a-zA-Z]*[-._+])*[0-9a-zA-Z]+@[0-9a-zA-Z]+([-.][0-9a-zA-Z]+)*([0-9a-zA-Z]*[.])[a-zA-Z]{2,6}$/;
export const MultipleEmailAddressRegex = /^([A-Za-z0-9.-_]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,})(;(\s){0,1}[A-Za-z0-9.-]+@[A-Za-z0-9.-_]+\.[A-Za-z]{2,}){0,10}(;)?$/;

export const blurOnEnterKey = (e) => {if (e.keyCode === 13) { e.target.blur() }}
