/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import apiAdmin from '../../services/api-admin';
import { useForm, Controller } from "react-hook-form";
import { TextField, MenuItem } from '@mui/material';
import { MultipleEmailAddressRegex, SingleEmailAddressRegex } from '../../services/utilities';
import { DigitalClock } from '@mui/x-date-pickers/DigitalClock';
import { DateTime } from 'luxon';

const TextAPickUp = (props) => {
    const cities = ['Boise', 'Caldwell', 'Eagle', 'Emmett', 'Idaho Falls', 'Jerome', 'Meridian', 'Middleton', 'Nampa', 'Star', 'Twin Falls', 'Other']
    const drivers = [{ name: 'Treasure Valley', email: 'deliveries_tv@titleonecorp.com' },
    { name: 'Idaho Falls', email: 'ifdeliveries@titleonecorp.com' },
    { name: 'Magic Valley', email: 'mvwdeliveries@titleonecorp.com' }, ]
    const { register, handleSubmit, reset, formState: { errors }, control  } = useForm();
    const [selectedCity, setSelectedCity] = useState(cities[0]);
    const [selectedDriver, setSelectedDriver] = useState(drivers[0].name);
    const [submitted, setSubmitted] = useState(false);
    const [error, setError] = useState(null);
    const [nextDay, setNextDay] = useState(false);

    useEffect(() => {
        setNextDay(new Date().getHours() > 14);
    }, []);
   
    const onSubmit = data => {

        const textAPickUpObject = {
            ...data,
            city: selectedCity,
            deliveriesRegion: drivers.find(s => s.name === selectedDriver).name, 
            deliveriesEmail: drivers.find(s => s.name === selectedDriver).email, 
            nextDay: nextDay,
            needBy: data.needBy ? data.needBy.toISO() : getNextHalfHour().plus({ minutes: 30 }).toISO()
        }
  
        const send = async () => {
            await apiAdmin.post(`/send-text-a-pick-up`, textAPickUpObject)
                .then((response) => {
                    if (response.statusText === "OK") {
                        setSubmitted(true);
                        reset();
                    } else { 
                        setError("There was an error sending the delivery request. Please try again.")
                    }
                });
        }
        send();
    }

    const getNextHalfHour = () => {
        const now = DateTime.now();
        if (now.hour > 14) {
            return now.plus({ days: 1 }).set({ hour: 8, minute: 30 });
        }
        if (now.minute > 30) {
            return now.set({ hour: now.hour + 1, minute: 0 });
        }

        return now.set({  minute: 30 });
    }

    return (
        <div>
            <Helmet>
                <title>Text A Pick Up</title>
            </Helmet>
            <h1>Text A Pick Up</h1>
            {submitted && <><div className="alert alert-success alert-dismissible" role="alert">Delivery request sent succesfully!<button type="button" className="btn-close" onClick={() => setSubmitted(false)} aria-label="Close"></button></div></>}
            {error && <div className="alert alert-danger" role="alert">{error}</div>}
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="border p-3">
                    <div className="mb-3">
                        <TextField
                            label="From Email*"
                            {...register("fromEmail", { required: true, pattern: SingleEmailAddressRegex })}
                            className="w-100"
                        />
                        {errors?.fromEmail?.type === "required" && <small className="text-danger">This field is required</small>}
                        {errors?.fromEmail?.type === "pattern" && <small className="text-danger">Please enter a valid email address, single email address only.</small>}
                    </div>
                    <div className="mb-3">
                        <TextField
                            label="CC Email(s)"
                            {...register("ccEmails", { pattern: MultipleEmailAddressRegex })}
                            className="w-100"
                            helperText="Enter multiple addresses separated by a semicolon (;)"
                        />
                        {errors?.ccEmails?.type === "pattern" && <small className="text-danger">Please leave blank or enter valid email addresses, separated by a semicolon (;)</small>}
                    </div>
                    <div className="mb-3">
                        <TextField
                            label="Pick Up From *"
                            {...register("pickUpFromContact", {required: true})}
                            className="w-100"
                        />
                        {errors?.pickUpFromContact?.type === "required" && <small className="text-danger">This field is required</small>}
                    </div>
                    <div className="mb-3">
                        <div className="form-check form-switch">
                            <label className="form-check-label" htmlFor="afterHoursDropBoxExists">After Hours Dropbox?</label>
                            <input className="form-check-input" type="checkbox" id="afterHoursDropBoxExists" {...register("afterHoursDropBoxExists")}  />
                        </div>
                    </div>
                    <div className="mb-3">
                        <TextField
                            label="Address *"
                            {...register("address", {required: true})}
                            className="w-100"
                        />
                        {errors?.address?.type === "required" && <small className="text-danger">This field is required</small>}
                    </div>
                    <div className="mb-3">
                        <TextField
                            select
                            label="City"
                            value={selectedCity}
                            className="w-100"
                            onChange={(e) => setSelectedCity(e.target.value)}
                        >
                            {cities.map((city, index) => (
                                <MenuItem key={"c_" + index} value={city}>
                                    {city}
                                </MenuItem>
                            ))}
                        </TextField>
                    </div>
                    <div className="mb-3">
                        <TextField
                            label="Deliver To (Name & Office) *"
                            {...register("deliverToContact", {required: true})}
                            className="w-100"
                        />
                        {errors?.deliverToContact?.type === "required" && <small className="text-danger">This field is required</small>}
                    </div>
                    <div className="mb-3">
                        <TextField
                            label="Buyer Name *"
                            {...register("buyerName", {required: true})}
                            className="w-100"
                        />
                        {errors?.buyerName?.type === "required" && <small className="text-danger">This field is required</small>}
                    </div>
                    <div className="mb-3">
                        <TextField
                            label="Property Address *"
                            {...register("propertyAddress", {required: true})}
                            className="w-100"
                        />
                        {errors?.propertyAddress?.type === "required" && <small className="text-danger">This field is required</small>}
                    </div>
                    <div className="mb-3">
                        <TextField
                            select
                            label="Driver"
                            value={selectedDriver}
                            className="w-100"
                            onChange={(e) => setSelectedDriver(e.target.value)}
                        >
                            {drivers.map((driver, index) => (
                                <MenuItem key={"d_" + index} value={driver.name}>
                                    {driver.name}
                                </MenuItem>
                            ))}
                        </TextField>
                        <a href="https://realogy.sharepoint.com/sites/TitleOneIntranet/Shared%20Documents/Forms/AllItems.aspx?id=%2Fsites%2FTitleOneIntranet%2FShared%20Documents%2FDeliveries%2FTV%20Delivery%20Schedule%2Epdf&parent=%2Fsites%2FTitleOneIntranet%2FShared%20Documents%2FDeliveries" target="_blank" rel="noreferrer">View Treasure Valley Delivery Schedule</a>
                    </div>
                    <div className="mb-3 d-flex justify-content-start">
                        <Controller
                            name="needBy"
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <DigitalClock value={value ? value : getNextHalfHour()} onChange={onChange} skipDisabled minTime={nextDay ? DateTime.now().set({ hour: 8, minute: 30 }) : getNextHalfHour()} maxTime={DateTime.now().set({ hour: 17, minute: 0 })} />
                            )}
                         />
                        {nextDay && <small className="text-danger p-2">Deliveries will be scheduled for the time selected on the next business day. For emergency late-day deliveries, please contact your Sales Rep.</small>}
                    </div>
                    <div className="mb-3">
                        <TextField
                            label="Item Description and Notes"
                            {...register("notes")}
                            className="w-100"
                            multiline
                            rows={3}
                        />
                    </div>
                </div>
                <div className="pt-2">
                    <button type="submit" className="btn btn-primary me-4">Submit</button>
                    <button type="button" className="btn btn-primary" onClick={() => { reset(); } }>Clear</button>
                </div>
            </form>
        </div>
    );
}

export default TextAPickUp;
